export const stringToColor = (string) => {
    let hash = 0;
    let i;
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
};

export const stringAvatar = (name) => {
    return {
        sx: {
            bgcolor: stringToColor(name),
            color: getContrastColor(stringToColor(name)),
        },
        children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
};

export function getContrastColor(bgColor) {
    // Calculate brightness of the color
    const r = parseInt(bgColor.substr(1, 2), 16);
    const g = parseInt(bgColor.substr(3, 2), 16);
    const b = parseInt(bgColor.substr(5, 2), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1000;

    // Return white or black text based on brightness
    return brightness > 125 ? 'black' : 'white';
}