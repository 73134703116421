import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Typography, Dialog, DialogActions, DialogContent, DialogTitle, useTheme, useMediaQuery, Stack, IconButton } from '@mui/material';
import { CalendarToday } from '@mui/icons-material';
import { format, eachDayOfInterval, startOfWeek, endOfWeek, addWeeks, addMonths, subMonths, isSameWeek, getISOWeek, isSameMonth, parse } from 'date-fns';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import { v4 as uuidv4 } from 'uuid';

export default function CustomWeekPicker({ isoWeek, getIsoWeek }) {
    const theme = useTheme();
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [selectedWeek, setSelectedWeek] = useState(null);
    const [open, setOpen] = useState(false);

    const [showIcon, setShowIcon] = useState(true);
    const buttonRef = useRef(null);
    const textRef = useRef(null);

    useEffect(() => {
        const buttonElement = buttonRef.current;
        const textElement = textRef.current;

        if (buttonElement && textElement) {
            // Get the computed styles
            const buttonStyles = window.getComputedStyle(buttonElement);
            const textStyles = window.getComputedStyle(textElement);

            // Calculate available width inside the button
            const buttonPadding =
                parseFloat(buttonStyles.paddingLeft) + parseFloat(buttonStyles.paddingRight);
            const buttonAvailableWidth = buttonElement.clientWidth - buttonPadding;

            // Measure text width with and without the icon
            const textWidth = textElement.scrollWidth;

            // Determine if the text wraps
            const isTextWrapping = textElement.clientHeight > parseFloat(textStyles.lineHeight);

            // Update state based on whether the text wraps
            if (isTextWrapping) {
                setShowIcon(false);
            } else {
                setShowIcon(true);
            }
        }
    }, []);

    const getWeeksInMonth = (month) => {
        const weeks = [];
        let weekStart = startOfWeek(new Date(month.getFullYear(), month.getMonth(), 1), { weekStartsOn: 1 });

        while (isSameMonth(weekStart, month) || isSameMonth(endOfWeek(weekStart, { weekStartsOn: 1 }), month)) {
            const days = eachDayOfInterval({ start: weekStart, end: endOfWeek(weekStart, { weekStartsOn: 1 }) });
            weeks.push({ days, weekNumber: getISOWeek(weekStart) });
            weekStart = addWeeks(weekStart, 1);
        }

        return weeks;
    };

    const weeksInMonth = getWeeksInMonth(currentMonth);

    const handleWeekClick = (weekStart) => {
        setSelectedWeek(weekStart);
    };

    const handlePrevMonth = () => setCurrentMonth(subMonths(currentMonth, 1));
    const handleNextMonth = () => setCurrentMonth(addMonths(currentMonth, 1));

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    const handleOk = () => {
        if (selectedWeek) {
            const newIsoWeek = format(selectedWeek, "RRRR'W'II");
            getIsoWeek(newIsoWeek);
        }
        handleClose();
    };

    useEffect(() => {
        const parsedIsoWeek = parse(isoWeek, "RRRR'W'II", new Date());
        setSelectedWeek(startOfWeek(parsedIsoWeek, { weekStartsOn: 1 }));

        if (!isSameMonth(currentMonth, parsedIsoWeek)) {
            setCurrentMonth(parsedIsoWeek);
        }
    }, [isoWeek]);

    return (
        <>
            <Button
                sx={{ fontSize: showIcon ? '1rem' : '0.8rem' }}
                variant="outlined"
                onClick={handleOpen}
                endIcon={showIcon ? <CalendarToday /> : null}
                ref={buttonRef}
            >
                <span ref={textRef}>{`Week ${getISOWeek(selectedWeek || new Date())}`}</span>
            </Button>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>{selectedWeek ? format(selectedWeek, "RRRR'W'II") : isoWeek}</DialogTitle>
                <DialogContent sx={{ p: 0 }}>
                    <Box sx={{ width: '100%', p: 2, borderRadius: 2 }}>
                        <Box display="flex" justifyContent="center" alignItems="center" mb={2}>
                            <Stack direction="row" spacing={1} alignItems="center">
                                <IconButton size="small" color="primary" aria-label="back" onClick={handlePrevMonth}>
                                    <ArrowBackIosIcon />
                                </IconButton>
                                <Typography variant="h6" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', fontSize: '1rem' }}>
                                    {format(currentMonth, 'MMMM yyyy')}
                                </Typography>
                                <IconButton size="small" color="primary" aria-label="forward" onClick={handleNextMonth}>
                                    <ArrowForwardIosIcon />
                                </IconButton>
                            </Stack>
                        </Box>

                        <Box display="grid" gridTemplateColumns="35px repeat(7, 1fr)" gap={0.25} mb={1} sx={{ maxWidth: '300px', margin: '0 auto' }}>
                            <Typography
                                variant="subtitle2"
                                align="center"
                                sx={{ fontSize: '0.9rem', lineHeight: '38px' }}
                            >
                                W
                            </Typography>
                            {['M', 'T', 'W', 'T', 'F', 'S', 'S'].map((day) => (
                                <Typography
                                    key={uuidv4()}
                                    variant="subtitle2"
                                    align="center"
                                    sx={{ fontSize: '0.9rem', lineHeight: '38px' }}
                                >
                                    {day}
                                </Typography>
                            ))}
                        </Box>

                        {weeksInMonth.map((week, index) => (
                            <Box
                                key={uuidv4()}
                                display="grid"
                                gridTemplateColumns="35px repeat(7, 1fr)"
                                gap={0.25}
                                mb={0.5}
                                alignItems="center"
                                onClick={() => handleWeekClick(week.days[0])}
                                sx={{
                                    cursor: 'pointer',
                                    // bgcolor: selectedWeek && isSameWeek(week.days[0], selectedWeek, { weekStartsOn: 1 }) ? theme.palette.primary.main : 'transparent',
                                    color: selectedWeek && isSameWeek(week.days[0], selectedWeek, { weekStartsOn: 1 }) ? 'white' : 'inherit',
                                    borderRadius: '0',
                                    borderTopLeftRadius: '38px',
                                    borderBottomLeftRadius: '38px',
                                    borderTopRightRadius: '38px',
                                    borderBottomRightRadius: '38px',
                                    // '&:hover': {
                                    //     bgcolor: theme.palette.primary.light,
                                    // },
                                    maxWidth: '300px',
                                    margin: '0 auto',
                                }}
                            >
                                <Typography
                                    align="center"
                                    sx={{
                                        fontWeight: 'bold',
                                        bgcolor: theme.palette.primary.dark,
                                        color: 'white',
                                        p: 1,
                                        fontSize: '0.9rem',
                                        lineHeight: '35px',
                                        borderRadius: '50%',
                                        width: 35,
                                        height: 35,
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        mx: 'auto'
                                    }}
                                >
                                    {week.weekNumber}
                                </Typography>
                                {week.days.map((day) => (
                                    <Box
                                        key={uuidv4()}
                                        display="flex"
                                        justifyContent="center"
                                        alignItems="center"
                                        sx={{
                                            width: 35,
                                            height: 35,
                                            borderRadius: '50%',
                                            bgcolor: selectedWeek && isSameWeek(day, selectedWeek, { weekStartsOn: 1 })
                                                ? theme.palette.primary.main
                                                : theme.palette.background.paper,
                                            // '&:hover': { bgcolor: theme.palette.action.selected },
                                            margin: '0px',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            mt: 0.1,
                                            mb: 0.1
                                        }}
                                    >
                                        <Typography
                                            sx={{
                                                fontSize: '0.9rem',
                                                color: selectedWeek && isSameWeek(day, selectedWeek, { weekStartsOn: 1 })
                                                    ? 'white'
                                                    : 'text.primary',
                                            }}
                                        >
                                            {format(day, 'd')}
                                        </Typography>
                                    </Box>
                                ))}
                            </Box>
                        ))}
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">Cancel</Button>
                    <Button onClick={handleOk} color="primary">OK</Button>
                </DialogActions>
            </Dialog>
        </>
    );
}