import React from 'react';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import WeekPicker from '../WeekPicker';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import moment from 'moment';
import UserToggleButton from './UserToggleButton';

export default function ScheduleControls(props) {
    const { isoWeek, setIsoWeek, singleUser, setSingleUser } = props;
    const [weekForButton, setWeekForButton] = React.useState(isoWeek); // New state for button week changes

    const getIsoWeek = (week) => {
        setIsoWeek(week);
    };

    const handleMoveDate = (direction) => {
        const newIsoWeek = moment(isoWeek, 'GGGG[W]WW').add(direction, 'week').format('GGGG[W]WW');
        setIsoWeek(newIsoWeek); // Directly set the isoWeek, triggering updates in WeekPicker
    };

    return (
        <div>
            <Stack spacing={2}>
                <Stack direction="row" spacing={1} alignItems="center">
                <WeekPicker getIsoWeek={setIsoWeek} isoWeek={isoWeek} weekForButton={weekForButton} />
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            height: '100%',
                        }}
                    >
                        <Stack direction="row" spacing={2}>
                            <IconButton size="large" color="primary" aria-label="back" onClick={() => handleMoveDate(-1)}>
                                <ArrowBackIosIcon />
                            </IconButton>
                            <IconButton size="large" color="primary" aria-label="forward" onClick={() => handleMoveDate(1)}>
                                <ArrowForwardIosIcon />
                            </IconButton>
                        </Stack>
                    </Box>
                    <UserToggleButton 
                        singleUser={singleUser}
                        setSingleUser={setSingleUser}
                    />
                </Stack>
                {/* EmployeePicker and other components */}
            </Stack>
        </div>
    );
}