import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import { startOfISOWeek, addDays, getDay } from 'date-fns';

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: 0, // Remove padding to prevent distortion
    textAlign: 'center',
    color: theme.palette.text.secondary,
    borderBottom: `0.5px solid ${theme.palette.divider}`, 
    borderRight: `0.5px solid ${theme.palette.divider}`, 
    borderRadius: 0, 
    boxShadow: 'none', 
    display: 'flex', // Use flexbox to center content
    alignItems: 'center', // Center content vertically
    justifyContent: 'center', // Center content horizontally
    '&:nth-of-type(7n+1)': {
        borderLeft: `0.25px solid ${theme.palette.divider}`, // Add left border for first column in each row
    },
    '&:nth-of-type(-n+7)': {
        borderTop: `0.25px solid ${theme.palette.divider}`, // Add top border for the first row
    },
    '&:last-child': {
        borderRight: `0.5px solid ${theme.palette.divider}`, // Ensure right border on the last item
        borderBottom: `0.5px solid ${theme.palette.divider}`, // Ensure bottom border on the last item
    },
}));

// Function to determine button size and font size based on screen size
const getButtonSize = (isSmallScreen, theme) => {
    const size = isSmallScreen ? theme.spacing(3.5) : theme.spacing(5);
    return {
        width: size,
        height: size, // Ensures the button is a perfect circle
        fontSize: isSmallScreen ? theme.typography.pxToRem(12) : theme.typography.pxToRem(16), // Adjust font size
    };
};

const DayButton = styled(({ isSmallScreen, selected, ...otherProps }) => <Button {...otherProps} />)(
    ({ theme, isSmallScreen, selected }) => {
        const borderColor = selected
            ? theme.palette.primary.dark
            : theme.palette.mode === 'dark'
            ? theme.palette.grey[600] // Use a darker grey in dark mode
            : theme.palette.grey[400]; // Use a lighter grey in light mode

        return {
            ...getButtonSize(isSmallScreen, theme),
            borderRadius: '50%', // Make the button round
            margin: '10px',
            color: selected ? theme.palette.common.white : borderColor,
            border: `1px solid ${borderColor}`, // Adjust border based on theme mode
            backgroundColor: selected ? theme.palette.primary.main : 'transparent', // Highlight selected day
            padding: 0, // Ensure no padding interferes with the circle shape
            minWidth: 0, // Ensure no minWidth is applied
            '&:hover': {
                backgroundColor: selected ? theme.palette.primary.dark : theme.palette.action.hover, // Hover effect
            },
        };
    }
);

const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];

export default function ScheduleWeekSegment(props) {
    const { isoWeek, dayIndex, setDayIndex } = props;
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm')); // Detect small screens
    const [selectedDayIndex, setSelectedDayIndex] = useState(dayIndex); // Initialize with dayIndex prop

    useEffect(() => {
        setSelectedDayIndex(dayIndex); // Sync selectedDayIndex with dayIndex prop
    }, [dayIndex]);

    // Generate an array of dates for the week
    const year = parseInt(isoWeek.substring(0, 4), 10);
    const week = parseInt(isoWeek.substring(5), 10);
    const startDate = startOfISOWeek(new Date(year, 0, (week - 1) * 7 + 1));
    const weekDates = Array.from({ length: 7 }, (_, i) => addDays(startDate, i));

    return (
        <Box sx={{ flexGrow: 1 }}>
            <Grid container spacing={0}>
                {/* Row for days of the week */}
                {daysOfWeek.map((day, index) => (
                    <Grid item xs={12 / 7} sm={12 / 7} md={12 / 7} key={index}>
                        <Item>{isSmallScreen ? day.charAt(0) : day}</Item> {/* Show first letter on small screens */}
                    </Grid>
                ))}
                {/* Row for corresponding dates as buttons inside Paper */}
                {weekDates.map((date, index) => (
                    <Grid item xs={12 / 7} sm={12 / 7} md={12 / 7} key={index}>
                        <Item>
                            <DayButton
                                isSmallScreen={isSmallScreen}
                                selected={selectedDayIndex === index} // Apply selected state
                                onClick={() => {
                                    setSelectedDayIndex(index);
                                    setDayIndex(index); // Update parent component
                                }} // Handle click to select day
                            >
                                {date.getDate()}
                            </DayButton>
                        </Item>
                    </Grid>
                ))}
            </Grid>
        </Box>
    );
}