import React, { useEffect, useState } from 'react';
import ScheduleWeekSegment from '../components/Schedule/ScheduleWeekSegment';
import ScheduleDaySegment from '../components/Schedule/ScheduleDaySegment';
import ScheduleControls from '../components/Schedule/ScheduleControls';
import ProjectDialog from '../components/Schedule/ProjectDialog';
import { Divider, Stack, Typography, Box } from '@mui/material';
import moment from 'moment';
import { getISOWeek, getISOWeekYear, getDay, addDays, startOfISOWeek } from 'date-fns';
import { useTheme } from '@emotion/react';
import ScheduleDataService from '../services/Schedule.services';

export default function Schedule(props) {
    const { employees, user } = props;
    const { token } = props;
    const [project, setProject] = useState(null);
    const [openDialog, setOpenDialog] = useState(false);
    const [isoWeek, setIsoWeek] = useState(moment(new Date()).format('GGGG[W]WW'));
    const [weekData, setWeekData] = useState([]);
    const [dayIndex, setDayIndex] = useState(0);
    const [singleUser, setSingleUser] = useState(true);
    const [menuSelection, setMenuSelection] = useState(0);
    const theme = useTheme();

    useEffect(() => {
        // Fetch schedule data from the API based on the isoWeek
        const loadScheduleData = async () => {
            try {
                const response = await ScheduleDataService.getScheduleByWeek(isoWeek, token);
                const data = response.data;
                setWeekData(data.matrix); // Set the matrix array from the API response
    
                // Set the dayIndex to today's index if the isoWeek is the current week
                const currentWeek = getISOWeek(new Date());
                const currentYear = getISOWeekYear(new Date());
                const selectedWeek = parseInt(isoWeek.substring(5), 10);
                const selectedYear = parseInt(isoWeek.substring(0, 4), 10);
    
                if (selectedWeek === currentWeek && selectedYear === currentYear) {
                    const today = getDay(new Date());
                    const todayIndex = today === 0 ? 6 : today - 1; // Adjust index: Sunday (0) should be last, Monday (1) should be first
                    setDayIndex(todayIndex);
                } else {
                    setDayIndex(0); // Reset if it's not the current week
                }
            } catch (error) {
                console.error("Failed to load schedule data from the API:", error);
                setWeekData([]); // Reset the data if it fails to load
                setDayIndex(0); // Reset if it's not the current week
            }
        };
    
        loadScheduleData();
    }, [isoWeek, token]); // Add token as a dependency if it's needed for the API call

    // Generate the date based on the isoWeek and dayIndex
    const year = parseInt(isoWeek.substring(0, 4), 10);
    const week = parseInt(isoWeek.substring(5), 10);
    const startDate = startOfISOWeek(new Date(year, 0, (week - 1) * 7 + 1));
    const selectedDate = addDays(startDate, dayIndex);
    const formattedDate = moment(selectedDate).format('dddd MMMM Do'); // e.g., "September 20th"

    const sortEmployeesByProject = (employees, dayIndex, user, singleUser) => {
        // Find the current user's employee data
        const userEmp = employees.find(emp => emp.employee.id === user.id);

        let filteredEmployees = employees;

        if (singleUser && userEmp) {
            // Get the user's project for the selected day
            const userProjects = (userEmp.days[dayIndex] && userEmp.days[dayIndex].projects) || [];

            // Filter employees to only include those who have at least one project in common with userEmp
            filteredEmployees = employees.filter(emp => {
                const employeeProjects = (emp.days[dayIndex] && emp.days[dayIndex].projects) || [];
                return employeeProjects.some(project =>
                    userProjects.some(userProject => userProject.name === project.name)
                );
            });
        }

        return filteredEmployees.sort((a, b) => {
            const projectA = (a.days[dayIndex] && a.days[dayIndex].projects[0] && a.days[dayIndex].projects[0].name) || '';
            const projectB = (b.days[dayIndex] && b.days[dayIndex].projects[0] && b.days[dayIndex].projects[0].name) || '';

            const isSpecialStatusA = a.days[dayIndex] && (a.days[dayIndex].shop || a.days[dayIndex].holiday || a.days[dayIndex].vacation);
            const isSpecialStatusB = b.days[dayIndex] && (b.days[dayIndex].shop || b.days[dayIndex].holiday || b.days[dayIndex].vacation);

            if (isSpecialStatusA && !isSpecialStatusB) return 1;
            if (!isSpecialStatusA && isSpecialStatusB) return -1;

            return projectA.localeCompare(projectB);
        });
    };

    return (
        <div>
            <Stack spacing={2}>
                <Stack
                    spacing={2}
                    sx={{
                        pb: 1,
                        position: 'sticky',
                        top: 0,
                        zIndex: 1,
                        bgcolor: (theme) => theme.palette.background.default
                    }}
                >
                    <ScheduleControls
                        isoWeek={isoWeek}
                        setIsoWeek={setIsoWeek}
                        employees={employees}
                        user={user}
                        setSingleUser={setSingleUser}
                        singleUser={singleUser}
                    />
                    <ScheduleWeekSegment
                        isoWeek={isoWeek}
                        setDayIndex={setDayIndex}
                        dayIndex={dayIndex}
                    />
                    <Typography variant="h6" color="textSecondary" align="center">{formattedDate}</Typography>
                </Stack>
                {weekData.length === 0 ? (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', flexDirection: 'column', textAlign: 'center' }}>
                        <Typography variant="h6" color="textSecondary">No Schedule Posted</Typography>
                        <Typography variant="body2" color="textSecondary">Please check back later</Typography>
                    </div>
                ) : (
                    <div>
                        {sortEmployeesByProject(weekData, dayIndex, user, singleUser).map((employeeData, index) => (
                            employeeData.days
                                .filter((_, i) => i === dayIndex) // Filter the days based on the selected dayIndex
                                .filter((day) => {
                                    // Only show if singleUser is true and the employee matches the user, or they share a project
                                    if (singleUser) {
                                        return employeeData.employee.id === user.id ||
                                            (day && day.projects && day.projects.some(project => {
                                                const userEmp = weekData.find(emp => emp.employee.id === user.id);
                                                const userDay = userEmp && userEmp.days[dayIndex];
                                                return userDay && userDay.projects && userDay.projects.some(userProject => userProject.name === project.name);
                                            }));
                                    }
                                    return true;
                                })
                                .map((day, dayIndex) => (
                                    // Only render the ScheduleDaySegment if the day object exists
                                    day ? (
                                        <Box key={`${index}-${dayIndex}`} sx={{ mb: 2 }}>
                                            <ScheduleDaySegment
                                                token={token}
                                                isoWeek={isoWeek}
                                                day={day}
                                                employee={employeeData.employee}  // Pass the employee object
                                                user={user}
                                                setProject={setProject}
                                                setOpenDialog={setOpenDialog}
                                                setMenuSelection={setMenuSelection}
                                            />
                                        </Box>
                                    ) : null // Don't render anything if `day` is undefined or null
                                ))
                        ))}
                    </div>
                )}
            </Stack>
            <ProjectDialog
                user={user}
                token={token}
                project={project}
                open={openDialog}
                setOpen={setOpenDialog}
                menuSelection={menuSelection}
            />
        </div>
    );
}