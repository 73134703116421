import React from 'react';
import ProjectServices from '../../services/Project.services';
import { Card, CardContent, Typography, Stack, Avatar, Paper } from '@mui/material';
import { stringAvatar, stringToColor, getContrastColor } from '../../utilities/AvatarModifiers';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import FlightIcon from '@mui/icons-material/Flight';
import BeachAccessIcon from '@mui/icons-material/BeachAccess';
import EventIcon from '@mui/icons-material/Event';
import HandymanIcon from '@mui/icons-material/Handyman';
import { useTheme } from '@mui/material/styles';
import HolidayIcon from '../../assets/icons/holiday_icon.svg';
import VacationIcon from '../../assets/icons/vacation_icon.svg';
import ShopIcon from '../../assets/icons/shop_icon.svg';
import AntennaIcon from '../../assets/icons/antenna_icon.svg';
import ButtonBase from '@mui/material/ButtonBase';

export default function ScheduleDaySegment(props) {
    const { day, employee, setProject, setOpenDialog, setMenuSelection } = props;
    const { token } = props;
    const fullName = `${employee.first_name} ${employee.last_name}`;
    const theme = useTheme();

    const getBackgroundColorWithOpacity = (color, opacity) => {
        const rgbColor = color.match(/\w\w/g).map(c => parseInt(c, 16)).join(', ');
        return `rgba(${rgbColor}, ${opacity})`;
    };

    // Determine background color for the avatar based on day properties
    const getAvatarBackgroundColor = () => {
        if (day.projects.some(project => project.testing)) return '#cfd8dc'; // Testing
        if (day.vacation) return '#FFFFCC'; // Vacation
        if (day.shop) return '#e1bee7'; // Shop
        if (day.holiday) return '#b2ebf2'; // Holiday
        return theme.palette.background.paper; // Default background
    };

    // Check if there is any content to display
    const hasContent = day.projects.length > 0 || day.vehicle || day.travel || day.vacation || day.holiday || day.shop;

    // Do not render the card if there is no content
    if (!hasContent) {
        return null;
    }

    // Determine which icon to show based on day properties
    const getTopRightIcon = () => {
        if (day.projects.some(project => project.testing)) return AntennaIcon; // Testing
        if (day.vacation) return VacationIcon; // Vacation
        if (day.shop) return ShopIcon; // Shop
        if (day.holiday) return HolidayIcon; // Holiday
        return null;
    };

    const TopRightIcon = getTopRightIcon();

    const handleGetProject = (projectNumber) => {
        if (projectNumber.startsWith('SVC')) {
            setMenuSelection(1);
        } else if (projectNumber.startsWith('HSE')) {
            setMenuSelection(2);
        } else {
            setMenuSelection(0);
        }
        // console.log(`Getting project: ${projectNumber}`);
        // setIsLoading(true);
        ProjectServices.get(projectNumber, token)
            .then(response => {
                setProject(response.data[0]);
                setOpenDialog(true);
            })
            .catch( e => {
                console.log(e);
                // setIsLoading(false);
            })
            .finally(() => {
                // setIsLoading(false);
            });
    };
    

    return (
        <Card variant="outlined" sx={{ position: 'relative' }}>
            <CardContent>
                {TopRightIcon && (
                    <Avatar
                        sx={{
                            bgcolor: getAvatarBackgroundColor(),
                            width: 40,
                            height: 40,
                            position: 'absolute',
                            top: 8,
                            right: 8,
                            border: (day.projects.some(project => project.testing)) ? '' : '1px solid, rgba(0, 0, 0, 0.12)',
                        }}
                        variant="rounded"
                    >
                        <img src={TopRightIcon} alt="" style={{ width: '100%' }} />
                    </Avatar>
                )}

                <Stack direction="row" alignItems="center" spacing={2}>
                    <Avatar {...stringAvatar(fullName)} />
                    <Typography variant="h6" component="div">
                        {fullName}
                    </Typography>
                </Stack>

                {/* Day's Projects */}
                {day.projects.map((project, index) => (
                    <Card
                        variant="outlined"
                        sx={{
                            mt: 2,
                            bgcolor: getBackgroundColorWithOpacity(stringToColor(project.number), 0.6), // Set opacity to 60%
                            color: getContrastColor(stringToColor(project.number)),
                        }}
                        key={index}
                    >
                        <Stack sx={{ p: 1.5 }} direction="row" alignItems="center" spacing={2}>
                            <ButtonBase
                                sx={{
                                    p: 1,
                                    bgcolor: stringToColor(project.number),
                                    color: getContrastColor(stringToColor(project.number)),
                                    fontSize: '1.1rem',
                                    border: '0.5px solid',
                                    borderRadius: '5px',
                                    '&:hover': {
                                        bgcolor: getBackgroundColorWithOpacity(stringToColor(project.number), 0.8), // Darken the background color on hover
                                    },
                                }}
                                onClick={() => handleGetProject(project.number)}
                            >
                                {project.number}
                            </ButtonBase>
                            <Stack>
                                <Typography variant="subtitle1" component="div">
                                    {project.name}
                                </Typography>
                                <Typography variant="body2">
                                    Start Time: {project.startTime}
                                </Typography>
                                <Typography variant="body2">
                                    Start Location: {project.startLocation}
                                </Typography>
                            </Stack>
                        </Stack>
                    </Card>
                ))}

                {/* Vehicle Information */}
                {day.vehicle && (
                    <Card
                        variant="outlined"
                        sx={{
                            mt: 2,
                            bgcolor: stringToColor(day.vehicle.nickname),
                            color: getContrastColor(day.vehicle.nickname),
                        }}>
                        <Stack sx={{ p: 1 }} direction="row" alignItems="center" spacing={2}>
                            <DirectionsCarIcon
                                fontSize="large"
                                sx={{
                                    color: getContrastColor(stringToColor(day.vehicle.nickname)),
                                }}
                            />
                            <Typography
                                variant="subtitle1"
                                component="div"
                                sx={{
                                    color: getContrastColor(stringToColor(day.vehicle.nickname)),
                                }}
                            >
                                {day.vehicle.nickname}
                            </Typography>
                        </Stack>
                    </Card>
                )}

                {/* Travel Day Information */}
                {day.travel && (
                    <Card
                        variant="outlined"
                        sx={{
                            mt: 2,
                            bgcolor: theme.palette.info.main,
                            color: getContrastColor(theme.palette.info.main),
                        }}>
                        <Stack sx={{ p: 1 }} direction="row" alignItems="center" spacing={2}>
                            <FlightIcon fontSize="large" />
                            <Typography variant="subtitle1" component="div">
                                Travel Day
                            </Typography>
                        </Stack>
                    </Card>
                )}

                {/* Vacation Information */}
                {day.vacation && (
                    <Card variant="outlined" sx={{ mt: 2, bgcolor: getAvatarBackgroundColor(), color: getContrastColor(getAvatarBackgroundColor()) }}>
                        <Stack sx={{ p: 1 }} direction="row" alignItems="center" spacing={2}>
                            <BeachAccessIcon fontSize="large" />
                            <Typography variant="subtitle1" component="div">
                                Vacation
                            </Typography>
                        </Stack>
                    </Card>
                )}

                {/* Holiday Information */}
                {day.holiday && (
                    <Card variant="outlined" sx={{ mt: 2, bgcolor: getAvatarBackgroundColor(), color: getContrastColor(getAvatarBackgroundColor()) }}>
                        <Stack sx={{ p: 1 }} direction="row" alignItems="center" spacing={2}>
                            <EventIcon fontSize="large" />
                            <Typography variant="subtitle1" component="div">
                                Holiday
                            </Typography>
                        </Stack>
                    </Card>
                )}
                {/* Shop Information */}
                {day.shop && (
                    <Card variant="outlined" sx={{ mt: 2, bgcolor: getAvatarBackgroundColor(), color: getContrastColor(getAvatarBackgroundColor()) }}>
                        <Stack sx={{ p: 1 }} direction="row" alignItems="center" spacing={2}>
                            <HandymanIcon fontSize="large" />
                            <Typography variant="subtitle1" component="div">
                                Shop
                            </Typography>
                        </Stack>
                    </Card>
                )}
            </CardContent>
        </Card>
    );
}