import React, { useState } from 'react';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import PersonIcon from '@mui/icons-material/Person';
import GroupsIcon from '@mui/icons-material/Groups';

export default function UserToggleButton(props) {
    const { singleUser, setSingleUser } = props;

    const handleUserTypeChange = (event, newUserType) => {
        if (newUserType !== null) {
            setSingleUser(newUserType);
        }
    };



    return (
        <ToggleButtonGroup
            value={singleUser}
            exclusive
            onChange={handleUserTypeChange}
            aria-label="user type"
        >
            <ToggleButton value={true} aria-label="single user">
                <PersonIcon />
            </ToggleButton>
            <ToggleButton value={false} aria-label="multiple users">
                <GroupsIcon />
            </ToggleButton>
        </ToggleButtonGroup>
    );
}