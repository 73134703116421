import axios from "axios";
import { SERVER } from "./SERVER";

class ScheduleDataService {
    // Fetch the schedule for a specific ISO week
    getScheduleByWeek(isoWeek, token) {
        axios.defaults.headers.common["Authorization"] = "Token " + token;
        return axios.get(`${SERVER}/api/schedule/${isoWeek}/`);
    }
}

export default new ScheduleDataService();